import { createContext, useContext, useState } from "react";
import { IIndication } from "../types";
import { LoginContext } from "./LoginContext";
import { message } from "antd";

const Indicationdefault = {
    indications: [] as IIndication[],
    getIndication: () => {},
    editIndication: (value: IIndication) => {},
};

export const IndicationContext = createContext(Indicationdefault);

export function IndicationContextProvider({ children }: any) {
    const [indications, setIndications] = useState([]);
    const { dispatchL } = useContext(LoginContext);

    function getIndication() {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/form_indication/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    setIndications(res);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function editIndication(value: IIndication) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/form_indication/edit/`,
            {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(value),
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro ao editar!");
                } else {
                    message.success("Status salvo com sucesso!");
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => getIndication());
    }

    return (
        <IndicationContext.Provider
            value={{ indications, getIndication, editIndication }}
        >
            {children}
        </IndicationContext.Provider>
    );
}
