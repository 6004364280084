import { createContext, useContext, useEffect, useState } from "react";
import { IPreference } from "../types";
import { LoginContext } from "./LoginContext";

const prefenceObj: any = {
    id_preference: "",
    thumb: "",
    landscape: "",
    invoice_email: "",
    marketing_email: "",
};
export const PreferenceContext = createContext(prefenceObj);

export function PreferenceContextProvider({ children }: { children: any }) {
    const [preference, setPreference] = useState([]);
    const [preferenceFile, setPreferenceFile] = useState([]);
    const [policiesFile, setPoliciesFile] = useState([]);
    const { dispatchL } = useContext(LoginContext);
    const getPreference = () => {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/preference/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    setPreference(res[0]);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const getPreferenceFiles = (id_preference: string) => {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/preference_files/${id_preference}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    setPreferenceFile(res);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const getPoliciesFile = (id_preference: string) => {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/preference_police_files/${id_preference}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    setPoliciesFile(res);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const addPreference = (value: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/preference/`, {
            method: "post",
            headers: {
                // "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: value,
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    return getPreference();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const postPreferenceFiles = (value: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/preference_files/`, {
            method: "post",
            headers: {
                // "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: value,
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    return getPreference();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const postPoliciesFile = (value: any) => {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/preference_police_files/`,
            {
                method: "post",
                headers: {
                    // "Content-Type": "application/json;charset=UTF-8",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: value,
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    return getPreference();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const editPreference = (value: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/preference/`, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: value,
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    return getPreference();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
    const deletePreferenceFile = (value: any) => {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/preference_files/${value.id_preference_files}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    return getPreference();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const deletePoliciesFile = (value: any) => {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/preference_police_files/${value.id_preference_police_files}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    return getPreference();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <PreferenceContext.Provider
            value={{
                getPreference,
                preference,
                preferenceFile,
                addPreference,
                editPreference,
                getPreferenceFiles,
                postPreferenceFiles,
                deletePreferenceFile,
                getPoliciesFile,
                postPoliciesFile,
                deletePoliciesFile,
                policiesFile,
            }}
        >
            {children}
        </PreferenceContext.Provider>
    );
}
